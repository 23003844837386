import * as React from "react"
import { graphql, Link } from "gatsby"
import { Header, Layout } from '../components'

class Artists extends React.Component {
  render() {
    const {
      data: { artists }
    } = this.props

    return (
      <Layout>
        <div>
          <Header location={this.props.location} />
          <div style={{ paddingTop: '3.2rem' }}>
            <h2 style={{ fontSize: '3rem' }}>Artistes</h2>
            {
              artists.nodes.length > 0 && (
                <ul>
                  {
                    artists.nodes.map((item, index) => {
                      return (
                        <li key={`artists-item-${index}`}>
                          <Link to={`/artistes/${item.uid}`}>
                            {item.data.artist_name.text}
                          </Link>
                        </li>
                      )
                    })
                  }
                </ul>
              )
            }
          </div>
        </div>
      </Layout>
    )
  }
}

export default Artists;

export const artistsQuery = graphql`
  query artistsList {
    artists: allPrismicArtiste {
      nodes {
        uid
        data {
          description {
            html
          }
          status
          artist_name {
            text
          }
          images {
            image {
              localFile {
                publicURL
              }
            }
            legend {
              html
            }
          }
        }
      }
    }
  }
`